<template>
  <v-content>
    <v-container fluid>
      <v-row v-if="!magicMailSend" style="justify-content:center">
        <v-col md="6" sm="8" xs="10" style="align-items:center">
          <v-row style="justify-content:center">
            <div v-show="user === undefined" data-test="loader">
              Authenticating...
            </div>
            <!-- Offline instruction -->
            <div v-show="!networkOnLine" data-test="offline-instruction">
              <!-- TODO: Translate -->
              Please check your connection, login feature is not available
              offline.
            </div>
            <!-- TODO: Translate -->
            <v-col cols="6">
              <h2 class="login__title">Login</h2>
            </v-col>
            <v-col cols="6">
              <h2 class="login__title">Login</h2>
            </v-col>
        
          </v-row>
          <v-row>
            <v-col cols="6">
              <copy item="sendLoginEmail" :lang="0"></copy>
            </v-col>
            <v-col cols="6">
              <p><copy item="sendLoginEmail" :lang="1"></copy></p>
            </v-col>
          </v-row>
          <v-row>
            <v-text-field
              v-model="emailForLogin"
              label="email"
              outlined
            ></v-text-field>
            <v-btn
              v-show="user !== undefined && !user && networkOnLine"
              class="login__button"
              x-large
              depressed
              dark
              data-test="login-btn"
              @click="login"
            >
              <span v-if="!loading">Stuur / Envoyer</span>
              <v-progress-circular v-else indeterminate></v-progress-circular>
            </v-btn>
            
            
          </v-row>
        </v-col>
      </v-row>
      <v-row style="justify-content:center">
        <v-col md="6" sm="8" xs="10" style="align-items:center">
          <v-alert v-if="loginError" type="error">
            {{ loginError }}
          </v-alert>

          <v-alert v-if="magicMailSend" type="success">
            <copy item="loginMailIsSend" :lang="0"></copy>
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
  </v-content>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { isNil } from 'lodash'
import firebase from 'firebase/app'
import { desktop as isDesktop } from 'is_js'

export default {
  data: () => ({ loginError: null, emailForLogin: null, magicMailSend: false }),
  head: function() {
    return {
      title: {
        inner: 'Login'
      },
      meta: [
        {
          name: 'description',
          content: `Sign in or sign up to ${this.appTitle}`,
          id: 'desc'
        }
      ]
    }
  },
  computed: {
    ...mapState('authentication', ['user', 'actionCodeSettings']),
    ...mapState('notifications', ['loading']),
    ...mapState('admin', ['adminCopy']),
    ...mapState('app', ['networkOnLine', 'appTitle'])
  },
  watch: {
    user: {
      handler(user) {
        if (!isNil(user)) {
          const redirectUrl = isNil(this.$route.query.redirectUrl)
            ? '/booking'
            : this.$route.query.redirectUrl
          this.$router.push(redirectUrl)
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations('authentication', ['setUser']),
    ...mapActions('authentication', ['sendMagicLink']),
    async login() {
      // TODO: Move to store?

      const email = this.emailForLogin.trim()
      
      this.loginError = null
      
      this.sendMagicLink({ email: email });
      // this.findUser({ email: email }).then(res => {

      //   if (res) {
      //     new firebase.auth()
      //       .sendSignInLinkToEmail(email, this.actionCodeSettings)
      //       .then(result => {
      //         this.magicMailSend = true
      //         // The link was successfully sent. Inform the user.
      //         // Save the email locally so you don't need to ask the user for it again
      //         // if they open the link on the same device.
      //         window.localStorage.setItem('emailForSignIn', email)
      //       })
      //       .catch(function(error) {
      //         console.log(error)
      //       })
      //   } else {
      //     this.loginError = "Can't find this email address."
      //   }
      // })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
.login__title {
  // margin-top: 4rem;
  // margin-bottom: 4rem;
}

.login__button {
  margin-left: 1rem;
}
</style>
